import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { HomePageService } from '../../../services/home-page.service';
import { CategoryService } from '../../../services/category.service';
import { SocialSettingsModel } from '../../../common/models/social-settings-model'
import { CategoryModel } from '../../../common/models/category-model';
import { StaticPageModel } from '../../../common/models/static-page-model'
import { Constants } from '../../../common/constants/lsnetx.constants';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  socialSettings: SocialSettingsModel;
  showNewsletter: boolean;
  categoryList: Array<CategoryModel>;
  quickLinks: Array<StaticPageModel>;
  fbWidgetSrc: string;
  copyRTxt: string;
  httpUrl: string;
  loggedInUser: boolean = false;
  checkOutVisibility;
  _quickLinksSubsciption;
  _catSubscription;
  queryParams;
  _userStateSubscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public homePageService: HomePageService,
    private sanitizer: DomSanitizer,
    public dialogService: LsDialogService<OkayMessageComponent>,
    public location: Location,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.queryParams = { 'moveTo': 'profile' };
    this.checkOutVisibility = this.location.path().includes('/checkout')
    this.getSocialLinks();
    this.getCategories();
    if (Constants.VENDOR_DETAILS.onlineStoreSettingDTO != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.generalSettingsDTO != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.generalSettingsDTO.allowSubscription) {
      this.showNewsletter = true;
    } else {
      this.showNewsletter = false;
    }
    // if (isPlatformBrowser(this.platformId)) {
    //   if (localStorage.getItem('currentUser') != null) {
    //     this.loggedInUser = true;
    //   }
    // }
    this._userStateSubscription = this.userService.userStateModel.subscribe((userModel) => {
      if (Object.keys(userModel).length != 0) {
        if (userModel.userType == Constants.UserType.guest) {
          this.loggedInUser = false;
        }else{
        this.loggedInUser = true;        
        }
      } else {
        this.loggedInUser = false;
      }
    });
    this.getQuickLinks();
    this.copyRTxt = Constants.VENDOR_DETAILS.storeCopyrightText;
  }

  ngOnDestroy() {
    if (this._quickLinksSubsciption) {
      this._quickLinksSubsciption.unsubscribe();
    }
    if (this._catSubscription) {
      this._catSubscription.unsubscribe();
    }
    if (this._userStateSubscription) {
      this._userStateSubscription.unsubscribe();
    }
  }

  getSocialLinks() {
    this.socialSettings = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO;
    if (this.socialSettings) {
      if (this.socialSettings.fbClientKey != "" && this.socialSettings.fbClientSecret != "") {
        this.fbWidgetSrc = "https://www.facebook.com/plugins/likebox.php?href=https://www.facebook.com/" + this.socialSettings.fbClientKey
          + "&width=260&height=280&colorscheme=light&show_faces=false&header=true&hide_cover=true&stream=true&show_border=true&appId=" + this.socialSettings.fbClientSecret;
      }
    } else {
      this.homePageService.getStoreSettings("social", resp => {
        this.socialSettings = resp.data;
        if (resp.data.fbClientKey != "" && resp.data.fbClientSecret != "") {
          this.fbWidgetSrc = "https://www.facebook.com/plugins/likebox.php?href=https://www.facebook.com/" + resp.data.fbClientKey + "&width=260&height=280&colorscheme=light&show_faces=false&header=true&hide_cover=true&stream=true&show_border=true&appId=" + resp.data.fbClientSecret;
        }
      })
    }
  }


  getCategories() {
    this._catSubscription = this.homePageService.parentCategoriesObservable.subscribe((data) => {
      this.categoryList = data;
    });
  }

  getQuickLinks() {
    this._quickLinksSubsciption = this.homePageService.quicLinksObservable.subscribe((quickLinks) => {
      this.quickLinks = quickLinks
      if (this.quickLinks && this.quickLinks.length > 0) {
        this.quickLinks.forEach(model => {
          if (model.pageURL.includes("http") || model.pageURL.includes("https")) {
            this.httpUrl = model.pageURL;
          }
        })
      }
    })
  }

  subscribeNewsletter(error, input) {
    let email = input.value;
    if (email == "" || !this.validateEmail(email)) {
      error.className = "show errorLbl"
      error.innerHTML = "Invalid Email!!";
      input.className = "tfSubscriptionEmail error-tag";
    } else {
      error.className = "hide errorLbl";
      error.innerHTML = "";
      input.className = "tfSubscriptionEmail";
      this.homePageService.subscribeNewsletter(input.value, (resp) => {
        this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          input.value = "";
        })
      })
    }
  }

  validateEmail(email) {
    var at = email.indexOf("@");
    var dot = email.lastIndexOf(".");
    return !(at < 1 || at + 2 > dot || dot + 2 > email.length);
  }

}

